/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import QuotationView from './quotation-view'

const QuotationController = () => {
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState([])
  const [success, setSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const { i18n } = useTranslation()
  const getFormData = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'form-data',
      params: {
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setFormData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onSubmit = (values) => {
    setIsUpdating(true)
    const headers = {
      'Content-Type': 'application/form-data',
    }
    const keys = Object.keys(values)
    const bodyFormData = new FormData()
    keys.forEach((key) => {
      bodyFormData.append(key, values[key])
    })
    bodyFormData.append('form', 'Quotation Form')
    
    bodyFormData.append(
      'marketingConsent',
      values.accept2 === false? 0 : 1,
    )
    bodyFormData.append('emailData', values.email)
    // bodyFormData.append('accept2', true)
    console.log('data ', values)
    // console.log('data email ', values.email)
    makeRequest({
      headers,
      custom: true,
      method: 'POST',
      endPoint: 'quotation/submit-form',
      data: bodyFormData,
    })
      .then((resp) => {
        setIsUpdating(false)
        if (resp.data.success) {          
          setSuccess(true)
        } else {
          setHasError(true)
        }
      })
      .catch((err) => {
        console.error('ERROR', err)
        setIsUpdating(false)
      })
  }

  useEffect(() => {
    getFormData()
  }, [i18n.language])

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      window.scrollTo(0, 0)
    }
  }, [success])

  const viewProps = {
    loading,
    data: formData,
    isUpdating,
    success,
    onSubmit,
  }
  return <QuotationView {...viewProps} />
}

export default QuotationController
